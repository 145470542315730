import styled from "styled-components";

export const Container = styled.div`
    height: 59px;
    width: 300px;
    background-color: #2B2D42;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Avatar = styled.img`
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #EF233C;
`

export const Options = styled.div`
    display: flex;
    gap: 10px;

    svg {
        width: 24px;
        height: 24px;
        color: #EF233C;
        cursor: pointer;
    }
`