import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 300px;
    border-right: 1px solid #ddd;
    height: 100vh;
`