import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'


const firebaseConfig = {
    apiKey: "AIzaSyBmB41LnNjkKsdryEv9e51kVETD4P8psek",
    authDomain: "xet-global.firebaseapp.com",
    projectId: "xet-global",
    storageBucket: "xet-global.appspot.com",
    messagingSenderId: "650254885243",
    appId: "1:650254885243:web:ed70b176f0e4d870641dde"
  };

  const app = firebase.initializeApp(firebaseConfig)
  const db = app.firestore()
  const auth = app.auth()
  const provider = new firebase.auth.GoogleAuthProvider()

  export { db, auth, provider }