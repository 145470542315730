import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from "./services/firebase";
import Login from "./components/Login";
import Loading from "./components/Loading";
import Sidebar from './components/Sidebar';
import * as C from "./styles/app";
import Chat from './components/Chat';
import Default from './components/Default';

const App = () => {
  const [user, loading] = useAuthState(auth);
  const [userChat, setUserChat] = useState(null);
  const [activeChatId, setActiveChatId] = useState(null);

  useEffect(() => {
    const checkUserData = async () => {
      const userRef = db.collection("users").doc(user.uid);
      const docSnapshot = await userRef.get();
      if (!docSnapshot.exists) {
        await userRef.set({
          email: user.email,
          photoURL: user.photoURL,
        });
      }
    };

    if (user) {
      checkUserData();
    }
  }, [user]);

  if (loading) return <Loading />;
  if (!user) return <Login />;

  return (
    <C.Container>
      <Sidebar setUserChat={setUserChat} activeChatId={setActiveChatId} />
      {/* Conditional rendering based on userChat */}
      {!userChat && <Default />}
      {userChat && <Chat userChat={userChat} />}
    </C.Container>
  );
};

export default App;
